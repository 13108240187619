@import './../../../../assets/styles/helpers';
@import './../../../../assets/styles/mixins';

.organization_chart_wrapper {
  min-height: 100%;
 // border: 1px solid red;
}

.chart_wrapper {
  position: relative;
  width: 100%;
  height: 600px;
//  overflow: auto;
 // border: 1px solid green;
  margin: 50px 0 20px;
  background-color: $white;

  svg {
  //  @include font(300, center, Montserrat-Regular, 12px, 12px);
  }
}

.root_node {
  width: 326px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d3cfcf;
 // margin-top: 100px;
  border-radius: 10px;
  @include font(700, center, Montserrat-Bold, 16px, 16px);

  &>foreignObject {
    background-color: red;
  }
}

.null_dep_node {
  height: 175px;
  background-color: $grey_2;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  @include font(700, center, Montserrat-Bold, 1.2rem, 1.5rem);
}

.custom_node_wrapper {
  width: 326px;
  display: flex;
  flex-direction: column;
}

.reset_filters_button {
  max-width: 100px!important;
  min-width: 100px!important;
  height: auto!important;
  background-color: $active_blue;
  padding: 7px;
  cursor: pointer;
  border-radius: 7px;
  @include font(300, center, Montserrat-Regular, .8rem, 1.375rem)
}

.reports_buttons_wrapper {
 // bordeR: 1px solid blue;
 // display: flex;
 // justify-content: flex-end;
  position: absolute;
  top: -45px;
  right: 25px;
  height: 50px;
 // width: 90%;
 // max-width: 400px;
 // min-width: 250px;

  button {
    border-radius: 10px 10px 0px 0px;
  }

  button:first-child {
    margin-right: 18px;
  }
}

.search_wrapper {
  border: 1px solid red;

  input {
    border: 1px solid blue;
  }
}

//.draggble_zone {
//  border: 1px solid red;
//  width: 100%;
//  height: 300px;
//  position: relative;
//
//  &_element {
//    width: 30px;
//    height: 30px;
//    background-color: deeppink;
//    position: absolute;
//    cursor: move;
//  }
//}