@import './../fonts/fonts';

//миксин для марджинов и паддингов
@mixin adaptiv_value($property, $startSize, $minSize) {
  $addSize: $startSize - $minSize;

  #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{1920 - 320}));
}

////COMMON
@mixin font ($weight, $text-align, $family, $font-size, $line-height){
  font-family: $family;
  font-weight: $weight;
  font-size: $font-size;
  line-height: $line-height;
  text-align: $text-align;
}







