//для шрифтов
//$min-font-size-16: 1.2rem;
//$max-font-size-16: 1.6rem;
//$min-screen: 320px;
//$max-screen: 1920px;


//new
$yellow-lighter: #FFDA6E;
$yellow: #FFCF27;
$white: #ffffff;
$body: #F6F8FA;
$red: #EE2828;
$black: #000000;
$grey: #333;
$grey_1:  #3A3838;
$grey_2: #BDBABA;
$light_grey: #666666;
$grey_4: #848484;
$grey_5: #d3d1d1;
$white_smoke: #F5F5F5;
$questionnaire_dark_blue: #0d4594;
$questionnaire_light_grey: #c8c8c8;
$questionnaire_light_grey_2: #E6E6E6;
$questionnaire_light_sea_green: #15c98c;
$questionnaire_dark_slate_gray:  #495057;
$questionnaire_dark_gray: #9d9d9d;

$notifications_menu_body: #E4F7FA;


$button_with_image: #2697FE;

$link: #2697fe;
//commonSelect management
$teamManagement_modal_window_form_text_color: #9F9B9B;
$teamManagement_modal_window_separator_color: #BDBABA;
$teamManagement_status_green: #19EAAA;
$teamManagement_status_yellow: #FFCF27;
$teamManagementStatus_red: #EE2828;

$teamManagement_action_background: rgba(38, 151, 254, 0.1);

$frequencyColor: #C2E2FF;

$active_blue: #D9EDFF;
$active_red: #FE7B7B;

$error_message: #EFEDED;



$digitalPulse_item_header_main: #C2E2FF;
$digitalPulse_item_header: #FFCF27;
$dashboard_item_header: #C2E2FF;
$dashboard_text_color_grey: #827D7D;

$dashboard_text_color_grey_2: #8B8787;


//header
$header_border_color: #E5E5E5;

//onboarding
$header_with_logo_background: #EBEBEB;
$header_background: #077DFF;
$header_active_step_background: #234274;
$onboarding_body_background: #F6F8FA;
$onboarding_chosen_item_background: #19EAAA;
$onboarding_section_border_color: #E3E3E3;
$onboarding_selected_answer: #19eaaa;
$onboarding_assessment_image_background: #DCEEFF;
$onboarding_additional_questions_border: #C6BFBF;


//coaching corner
$motivation_header: #FFC20E;
$motivation_icons: #FFDA6E;
$textarea_color: #989595;
$motivatin_blue: #2697FE;

//graph colors
$graph_blue: #2697FE;
$graph_yellow: #FFC20E;
$graph_green: #19EAAA;
$graph_red: #E42C2C;

//switchAccount colors
$switch_account_blue: #2697FE;
$switch_account_grey: #565555;

//signIn
$form_header_background: #2176fe;

//oopsPage
$oops_background_color: #f6f6f6;

//page404
$page404_grey: #2e2f30;
$page404_button_color: #02aeef;


// primary colors
$red: #d00020;
//$grey: #9d9d9d;
$blue: #0d4594;
$green: #5eb319;
$green_2: #6EBC20;
$orange: #ffa500;
//$yellow: #feb710;
$logo-black: black;


// blues
$dark-blue: #052a5c;
$darkish-blue: #093878;
$blue: #0d4594; //primary
$lightish-blue: #275da7;
$light-blue: #4774b3;
$royla_blue: #4270b1;

//reds
$dark-red: #a9001b;
$red: #d00020; // primary
$light-red: #ef2343;
$lightest-red: #f67388;
$pale_violet_red_2: #f67086;
$crimson_2: #d01120;

//gray
$dark-grey: #707070;
//$light-grey: #c8c8c8;
$background-grey: #ececec;

//yellow
$dark-yellow: #cc8f00;
$yellow: #feb710;
$light-yellow: #ffd36b;
$orange_2: #ffa007;
$yellow_2: #FFC20E;
//green
$light-green: #7bc93b;
$lightest-green: #b7ed8b;







