@import "./../../../assets/styles/mixins";

.mini_sidebar {
  z-index: 110;
  display: none;
  width: 100%;
  padding: 35px 0;
  box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.15);
  border-radius: 0px 15px 15px 0px;

  & > a, .create_department_item {
    display: inline-block;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 2.5rem;
    cursor: pointer;
  }

  a:hover::after,
  .create_department_item:hover::after {
    content: attr(data-title);
    position: absolute;
    top: 0;
    left: 130%;
    background: #DCEEFF;
    padding: 5px 10px;
    color: black;
    box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.15);
    border-radius: 0px 15px 15px 0px;
    @include font(400, start, Montserrat-Light, 1rem, 1.25rem);
  }
}

@media screen and (max-width: 900px) {
  .mini_sidebar {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

