@import './../../../assets/styles/mixins';
@import './../../../assets/styles/helpers';

.sidebar {
  top: 0;
  width: 18.75rem;
  min-height: 90vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.15);
  border-radius: 0px 15px 15px 0px;
  @include font(500, center, Montserrat-Regular, 1rem, 1.25rem);

  & > div {

    &:nth-child(2) {
      flex-grow: 1;
    }

  }
}

.menu_item {
  display: flex;
  align-items: center;
  padding: 1.875rem 0 1.875rem 2.1875rem;
  cursor: pointer;

  &:hover {
    background-color: #DCEEFF;
  }

  & > div {
    cursor: pointer;
  }

  & > div:last-child {
    margin-left: 1rem;
  }
}

.menu_item.logo {
  &:hover {
    background-color: $white;
  }
}

@media screen and (max-width: 900px) {
  .sidebar {
    display: none;
  }
}
