@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.font {
  @include font(500, start, Montserrat-Medium, 1rem, 1.25rem)
}

.green {
  color: $teamManagement_status_green;

  & .radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;

    & + label {
      position: relative;
      padding: 0 0 0 1.5625rem;
      cursor: pointer;
    }

    & + label:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $teamManagement_status_green;
      border-radius: 50%;
      background: #ffffff;
    }

    & + label:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $teamManagement_status_green;
      opacity: 0;
      transition: .2s;
    }

    &:checked + label:after {
      opacity: 1;
    }

  }

}

.red {
  color: $teamManagementStatus_red;

  & .radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;

    & + label {
      position: relative;
      padding: 0 0 0 1.5625rem;
      cursor: pointer;
    }

    & + label:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $teamManagementStatus_red;
      border-radius: 50%;
      background: #ffffff;
    }

    & + label:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $teamManagementStatus_red;
      opacity: 0;
      transition: .2s;
    }

    &:checked + label:after {
      opacity: 1;
    }

  }

}

.yellow {
  color: $teamManagement_status_yellow;

  & .radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;

    & + label {
      position: relative;
      padding: 0 0 0 1.5625rem;
      cursor: pointer;
    }

    & + label:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $teamManagement_status_yellow;
      border-radius: 50%;
      background: #ffffff;
    }

    & + label:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $teamManagement_status_yellow;
      opacity: 0;
      transition: .2s;
    }

    &:checked + label:after {
      opacity: 1;
    }

  }

}

.action_button {
  display: block;
  width: 100%;
  max-width: 140px;
  margin: 0 auto;
  border: none;
  padding: 5px 0;
  color: $link;
  background: $teamManagement_action_background;
  border-radius: 5px;
  @include font(700, center, Montserrat-Bold, 1rem, 1.25rem);
  cursor: pointer;
}