@import "./../../../assets/styles/helpers";
@import './../../../assets/styles/mixins';

.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(157, 157, 157, .5);
  z-index: 115;
  overflow-y: auto;
}

.modal_window {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.125rem;
  margin-bottom: 1rem;
  width: 80%;
  max-width: 750px;
  background-color: $white;
  border-radius: 10px;

  &_header {
    position: relative;
    padding: 3.0625rem 0 2.6875rem 2.6875rem;

    p {
      @include font (600, start, Montserrat-SemiBold, 1.25rem, 1.5rem)
    }
  }

  &_separator {
    width: 100%;
    height: 1px;
    background-color: $teamManagement_modal_window_separator_color;
  }

  &_body {
    padding: 0 46px 42px 46px;

    p:first-child {
      @include font (500, start, Montserrat-Medium, 1.125rem, 1.375rem)

    }
  }
}

.error_message {
  margin-top: 10px;
  color: red;
  @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);
}

.cancel_button {
  position: absolute;
  top: 21px;
  right: 24px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}


@media screen and (max-width: 500px) {
  .modal_window_header {
    padding: 10px;
  }

  .cancel_button {
    width: 10px;
    height: 10px;
    top: 12px;
    right: 10px;
  }

  .modal_window_body {
    padding: 10px;
  }
}